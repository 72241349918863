import React, { useState, useEffect } from "react";
import axios from "axios";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router";
import { Box } from "@mui/system";
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { LoginURL } from '../../CommonServices/LoginAPIURL';
import { ToastContainer, toast } from 'react-toastify';
import { login, logout } from '../../reduxFeature/user';
import { useDispatch } from 'react-redux';


const LoginForm = ({ setRolesId, setShowLogin, setLoginData, setId, setEmail, setName, setUname, setRoles, setDepartment, setBranches, setBranchId, setBranchName, setBranchMedium, setAccountingType, setFeeType, setTrustId }) => {
    //{ setShowLogin, setLoginData}
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [data, setData] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {

        const getData = sessionStorage.getItem("inst-sss-d");
        if (getData) {
            console.log(JSON.parse(getData))
            const localUserData = (JSON.parse(getData));

            dispatch(login({ roleId: localUserData.roleId, roleName: localUserData.roleName, deptId: localUserData.deptId, deptName: localUserData.deptName, branchId: localUserData.branchId, branchName: localUserData.branchName, yearLabel: localUserData.yearLabel, sessionYear: localUserData.sessionYear, fromDate: localUserData.fromDate, toDate: localUserData.toDate, accApl: localUserData.accApl, branchMedium: localUserData.branchMedium, username: localUserData.username }));
            navigate("/Home/dashboard/");
            localStorage.setItem('isDashboard', 'true');
        }
    }, []);

    const getData = () => {
        console.log(`${LoginURL}?username=${username}&password=${password}`)
        axios
            .get(`${LoginURL}?username=${username}&password=${password}`)
            .then((response) => {
                if (response.data == "Username and Password not valid") {
                    //console.log("Username and Password not valid");
                    toast.error("Invalid User Id or Password");
                }
                else if (response.data == "BLOCKED") {
                    toast.warn("Application locked due to overdue AMC.");
                }
                else if (response.data != null) {
                    setData(response.data.id);
                    setId(response.data.id);
                    setEmail(response.data.email);
                    setName(response.data.name);
                    setUname(response.data.username);
                    setRoles(response.data.roles[0].name);
                    setRolesId(response.data.roles[0].id);
                    setDepartment(response.data.departments);
                    setBranches(response.data.branches);
                    setBranchName(response.data.branches[0].name);
                    setBranchId(response.data.branches[0].id);
                    setBranchMedium(response.data.branches[0].branchMedium);
                    setAccountingType(response.data.accountingType);
                    setFeeType(response.data.feeType);
                    setTrustId(response.data.trustId);
                    // console.log("trustId========" + response.data.trustId)
                    // console.log(response.data.branches[0].branchMedium);
                    setShowLogin(false);
                }
                else {
                    console.log("else")
                    toast.error("Operation failed.");
                }

            })
            .catch((error) => {
                console.log(error);
                toast.error(error)
            })

    }

    const handleSubmit = e => {
        e.preventDefault();
        getData();
        //setShowLogin(false);     
    };

    useEffect(() => {
        function handlekeydownEvent(event) {
            if (event.key === "Enter") {
                console.log("Enter is pressed!")
                event.preventDefault();

                let btn1 = document.querySelector('.myLoginBtn')
                if (btn1 !== null) {
                    btn1.click();
                }

            }
        }

        document.addEventListener('keypress', handlekeydownEvent)
        return () => {
            document.removeEventListener('keypress', handlekeydownEvent)
        }
    }, []);

    return (
        <>
            <div className="col-sm-12 p-0 m-0">
                <form onSubmit={handleSubmit}>
                    <div className="mt-4" style={{ padding: "5px" }}>
                        <h3 style={{ color: "#004692", padding: "16px 0px 0px 16px" }}><strong>LOGIN</strong></h3>
                        <div className="">
                            <div className="col-sm-12">
                                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <PersonRoundedIcon sx={{ color: '#0776EA', mr: 1, my: 0.5 }} />
                                    <TextField
                                        required={true}
                                        fullWidth
                                        value={username}
                                        onChange={e => setUsername(e.target.value)}
                                        onKeyPress={(e) => (e.key == "Enter") ? username : ""}
                                        size='small'
                                        id="input-with-sx"
                                        label="User Id"
                                        variant="standard"
                                        InputLabelProps={{ style: { fontSize: 14 } }} />
                                </Box>
                            </div>

                            <div className="col-sm-12 mt-2">
                                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <LockRoundedIcon sx={{ color: '#0776EA', mr: 1, my: 0.5 }} />
                                    <TextField
                                        required
                                        fullWidth
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        onKeyPress={(e) => (e.key == "Enter") ? password : ""}
                                        type="password"
                                        size='small'
                                        id="input-with-sx"
                                        label="Password"
                                        variant="standard"
                                        InputLabelProps={{ style: { fontSize: 14 } }}
                                    />
                                </Box>
                            </div>

                        </div>
                        <div className='col-sm-12 mt-4 mb-3'>
                            <Button type='submit' style={{ backgroundColor: "#0776EA", boxShadow: "0px 5px 10px #6C7A89", borderRadius: "20px", color: "white" }} className="myLoginBtn form-control" variant='contained'>Login</Button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="text-center my-3" style={{ fontSize: "12px", fontWeight: "500" }} >
                {/* सांगली शिक्षण संस्था, सांगली */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 सांगली शिक्षण संस्था</strong><br /> */}

                {/* पलूस शिक्षण प्रसारक मंडळ, पलूस */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 पलूस शिक्षण प्रसारक मंडळ, पलूस</strong><br /> */}

                {/* लठ्ठे एज्युकेशन सोसायटी */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 लठ्ठे एज्युकेशन सोसायटी</strong><br /> */}

                {/* मुस्लिम एज्युकेशन कमिटी, सांगली */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 मुस्लिम एज्युकेशन कमिटी, सांगली</strong><br />  */}

                {/* श्री. सिद्धेश्वर शिक्षण प्रसारक मंडळ, तुरची */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 श्री. सिद्धेश्वर शिक्षण प्रसारक मंडळ, तुरची</strong><br /> */}

                {/* भिलवडी शिक्षण संस्था */}
                <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 भिलवडी शिक्षण संस्था</strong><br />

                {/* एमटीईएस मराठी शाळा */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 एमटीईएस मराठी शाळा, सांगली</strong><br /> */}

                {/* पंडित विष्णू दिगंबर पलूसकर बहु. शिक्षण संस्था, पलूस */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 पंडित विष्णू दिगंबर पलूसकर बहु. शिक्षण संस्था, पलूस</strong><br /> */}

                {/* नागनाथ एज्युकेशन सोसायटी, नरंदे */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 नागनाथ एज्युकेशन सोसायटी, नरंदे</strong><br /> */}

                {/* विद्या विकास मंडळ, जामसंडे */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 विद्या विकास मंडळ, जामसंडे</strong><br /> */}

                {/* शिरगांव पंचक्रोशी (ता. देवगड) शिक्षण प्रसारक मंडळ, मुंबई */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 शिरगांव पंचक्रोशी (ता. देवगड) शिक्षण प्रसारक मंडळ, मुंबई</strong><br /> */}

                {/* देवगड एज्युकेशन बोर्ड, मुंबई */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 देवगड एज्युकेशन बोर्ड, मुंबई</strong><br /> */}

                {/* ज्ञानदीप शिक्षण प्रसारक मंडळ, शिरढोण */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 ज्ञानदीप शिक्षण प्रसारक मंडळ, शिरढोण</strong><br /> */}
                
                {/* पडेल ग्राम सुधारणा मंडळ, मुंबई */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 पडेल ग्राम सुधारणा मंडळ, मुंबई</strong><br /> */}

                {/* श्री शिवलिंगेश्वर शिक्षण संस्था, संख */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 श्री शिवलिंगेश्वर शिक्षण संस्था, संख</strong><br /> */}

                {/* तळेबाजार पंचक्रोशी शिक्षण प्रसारक मंडळ, तळेबाजार */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 तळेबाजार पंचक्रोशी शिक्षण प्रसारक मंडळ, तळेबाजार</strong><br /> */}

                {/* स्नेहल फाउंडेशन रुई संचलित */}
                {/* <strong style={{ fontWeight: "bold" }}>&copy; 2024-25 स्नेहल फाउंडेशन रुई</strong><br /> */}


                By <a href="https://technoaarv.com/" target="_blank">TechnoAarv Solution</a><br />
                <strong>Version : 5.0.1</strong>
            </div>

            <ToastContainer position="top-right" theme="colored" />
        </>
    )
}

export default LoginForm;